<template>
    <div class="bg-grey200">
        <div class="max-w-container md:max-w-md sm:max-w-sm mx-auto flex flex-col items-center">
            <h2 class="font-berlingske text-4.5xl sm:text-[1.875rem] leading-[3.25rem] text-center sm:leading-[2.125rem] mb-8 sm:px-8 sm:font-black">
                Les clés pour réussir son investissement
            </h2>

            <c-frise
                id="container-frise-home2"
                :index-start="0"
                :index-end="articles.length - 1"
                :coefGap="0.065"
                color-selected="#004D43"
                :show-arrows-with-dot="isMobile"
                custom-last
                class="sm:!max-w-[90vw]"
            >
                <a
                    v-for="(article, index) in articles"
                    :key="index"
                    class="pb-8 min-w-[14rem] sm:min-w-[21rem] w-full sm:mb-8 rounded bg-white flex flex-col justify-between gap-4 border group hover:shadow-lg"
                    :class="{'ml-4 sm:ml-3': index !== 0}"
                    :href="article.url"
                >
                    <div>
                        <div class="relative mb-4 w-full">
                            <nuxt-img
                                format="webp"
                                densities="1x"
                                sizes="lg:300px"
                                width="300"
                                :src="article.image"
                                class="w-full h-40 sm:h-72 rounded-t sm:mb-[.625rem] object-cover"
                                loading="lazy">
                            </nuxt-img>
                        </div>

                        <div class="text-center text-lg font-medium sm:min-h-[3rem] sm:overflow-hidden px-7">
                            {{ article.title }}
                        </div>
                    </div>

                    <div class="text-center">
                            <span class="rounded transition-all text-base border px-5 py-2 text-black group-hover:text-white border-black group-hover:bg-black mb-6 sm:mb-4 font-medium">
                                Lire l’article
                            </span>
                    </div>
                </a>

                <div class="ml-4 sm:ml-3 pt-10 pb-8 min-w-[14rem] sm:min-w-[21rem] w-full sm:mb-8 rounded bg-rose flex flex-col justify-between items-center gap-4">
                    <div class="flex flex-col items-center gap-2">
                        <icons-bell-alert-outline class="w-6 h-6 text-black" />

                        <div class="font-semibold tracking-widest">
                            BLOG MASLOW
                        </div>

                        <div class="text-tangerine font-berlingske text-3xl text-center tracking-wide mt-4 sm:mt-10 px-4 sm:px-12">
                            Tous nos conseils pour investir dans l’immobilier
                        </div>
                    </div>

                    <c-button
                        class="cursor-pointer bg-tangerine text-white text-sm flex items-center align-center !px-8 mt-8 rounded font-medium"
                        :href="blogUrl"
                    >
                        En savoir plus
                    </c-button>
                </div>
            </c-frise>
        </div>
    </div>
</template>

<script setup>
const isMobile = toRef(useNuxtApp(), '$isMobile');

const blogUrl = useRuntimeConfig().public.blogUrl;

const articles = computed(() => [
    {
        title: 'Pourquoi investir dans l’immobilier locatif en 2024 ?',
        url: `${blogUrl}/investissement-locatif-explications/`,
        image: '/images/articles/1.webp'
    },
    {
        title: 'Tout savoir de la location meublée non professionnelle (LMNP) en 2024',
        url: `${blogUrl}/lmnp-definition/`,
        image: '/images/articles/2.webp'
    },
    {
        title: 'Gestion locative : fonctionnement, avantages et tarif',
        url: `${blogUrl}/gestion-locative-explications/`,
        image: '/images/articles/3.webp'
    }
]);
</script>

<style scoped lang="scss">

</style>